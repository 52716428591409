<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <board-links-comp></board-links-comp>
                <div class="board_list"  v-if="$store.state.isLogin">
                    <div class="free_board" v-for="(item,index) in boardList" :key="index">
                        <div class="bid">{{item.id}}</div>
                        <div class="btitle">
                            <router-link :to="{path:'/board_detail/' + item.id}" tag="a">{{item.title}}</router-link>
                        </div>
                        <div class="bauthor">{{item.user.nickname}}</div>
                        <div class="btime">{{item.createTime}}</div>

                    </div>

                    <div class="">
                        <!--페이지-->
                        <pagination :page-index="pageNum"
                                    :total="total"
                                    :page-size="pageSize"
                                    @change="pageChange"
                                    v-if="boardList.length > 0"></pagination>
                    </div>
                </div>
                <!--글쓰기-->
                <div class="board_list" v-if="$store.state.isLogin">
                    <board-writer :bet-ids="betIds" :from="from" :board-type="sportsConst.TYPE_FREE_BOARD"
                                  @saveBoardSuccess="saveBoardSuccess"></board-writer>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import Pagination from "../../components/pagenation/Pagination";
    import BoardWriter from "../../components/BoardWriter";
    import {getBoardList} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import BoardLinksComp from "../../components/sports/BoardLinksComp";

    export default {
        name: "Board",
        components: {
            BoardLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeftBarComp, BoardWriter, Pagination, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                from: 0,//게시판에서 게시글 작성
                pageNum: 1,
                pageSize: 15,
                total: 1,
                search: {},
                boardList: [],
                betIds: [],
                clickNumber: 0,
            }
        },
        methods: {
            showContent(id) {
                if (id === this.clickNumber) {
                    this.clickNumber = 0
                } else {
                    this.clickNumber = id;
                }

            },
            initBoardList() {
                getBoardList(this.pageNum, this.pageSize).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.boardList = res.data.data
                        this.boardList.forEach(b => {
                            b.createTime = this.$moment(b.createTime, 'YYYY-MM-DD HH:mm').format('MM/DD');
                        })
                    }

                })
            },
            //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
            saveBoardSuccess() {
                this.initBoardList()
            },
            pageChange(page) {
                this.pageNum = page
                this.initBoardList()
            },
        },
        created() {
            if (!this.$store.state.isLogin) {
                this.$swal({
                    title: '로그인이 필요한 서비스 입니다.',
                    type: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$router.push('/login')
                return false;
            }
            this.initBoardList();
        }

    }
</script>

<style scoped>


</style>